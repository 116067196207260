const Countdown = (() => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const timer = document.getElementById('timer');

    function countDownHours(endTime) {
        const now = new Date().getTime();
        let distance = endTime - now;

        if (distance < 0) {
            distance = 0;
        }

        const hours = Math.floor(distance / (hour));
        const mins = Math.floor((distance % (hour)) / (minute));
        const secs = Math.floor((distance % (minute)) / second);

        const time = `${(hours < 10 ? '0' : '')}${hours}:${(mins < 10 ? '0' : '')}${mins}:${(secs < 10 ? '0' : '')}${secs}`;

        document.getElementById('js-counter_topbanner').innerText = time;
    }

    function countDownDays(endTime) {
        const now = new Date().getTime();
        const distance = endTime - now;

        let time = Math.floor(distance / (day));

        if (time < 0) {
            time = 0;
        }

        document.getElementById('js-counter_topbanner').innerText = time;
    }

    if (timer) {
        const endDate = timer.getAttribute('data-enddate');
        const counterType = timer.getAttribute('data-countertype');

        const endTime = new Date(endDate).getTime();

        switch (counterType.toLowerCase()) {
            case 'days':
                setInterval(countDownDays, second, endTime);
                break;
            case 'hours':
                setInterval(countDownHours, second, endTime);
                break;
            default:
                break;
        }
    }
})();

export default (() => {
    $(document).ready(Countdown);
})();
